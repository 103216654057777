import React, { useState } from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
const { Panel } = Collapse;

const AlertsForSite = (prop) => {
  const [openPanels, setOpenPanels] = useState({});

  const togglePanel = (alertId) => {
    setOpenPanels((prev) => ({ ...prev, [alertId]: !prev[alertId] }));
  };

  const message = prop.message.length > 0;

  return (
    <Collapse
      bordered={false}
      // defaultActiveKey={['1']}
      // activeKey={collapsible ? "1" : "2"}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
      className="site-collapse-custom-collapse"
      style={{
        border: "2px solid navyblue",
        background: prop.red ? "#6C0F02" : "#1f3161",
        borderRadius: 3,
        color: "white",
        alightItems: "center",
        marginRight: 3,
        fontWeight: 600,
        fontSize: 12,
        borderBottomColor: "red",
        borderBottomWidth: "4px",
      }}
      onChange={(key) => togglePanel(prop.id)}
      expandIconPosition="end"
      accordion={false}
    >
      <Panel
        key={prop.id}
        header={
          <div style={{ height: message ? 65 : 40, paddingBottom: 15 }}>
            <p>{prop.site_name}</p>
            {message && (
              <p
                style={{
                  marginBottom: 5,
                  padding: 0,
                  fontSize: 12,
                  color: "#d9d9d9",
                  fontWeight: "500",
                }}
              >
                <i>{prop.message.reverse()[0]}</i>
              </p>
            )}
            <div
              style={{
                paddingBottom: 20,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                fontSize: 10,
              }}
            >
              <p>
                <i>Total Alerts: </i> {prop.count}
              </p>{" "}
              <p style={{ marginLeft: 10 }}>
                {" "}
                <i>Latest Alert: </i> {prop.time}
              </p>
            </div>
            <br />
          </div>
        }
      >
        {openPanels[prop.id] && <>{prop.children}</>}
      </Panel>
    </Collapse>
  );
};

export default AlertsForSite;
